import { notifyError } from '../logger'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AliasCreateRequestType, AliasType, AliasUpdateRequestType, createAlias, deleteAlias, fetchAliases, updateAlias } from '../services/alias'

export const fetchAliasAsync = createAsyncThunk(
  'alias/fetch',
  async (_) => fetchAliases()
    .catch(err => {
      notifyError(err)
      return Promise.reject()
    })
)

export const createAliasAsync = createAsyncThunk(
  'alias/create',
  (alias: AliasCreateRequestType) => createAlias(alias)
    .catch(err => {
      notifyError(err)
      return Promise.reject(err)
    })
)

export const updateAliasAsync = createAsyncThunk(
  'alias/update',
  (alias: AliasUpdateRequestType) => updateAlias(alias)
    .catch(err => {
      notifyError(err)
      return Promise.reject()
    })
)

export const deleteAliasAsync = createAsyncThunk(
  'alias/delete',
  (id: string) => deleteAlias(id)
    .catch(err => {
      notifyError(err)
      return Promise.reject(err)
    })
)

const aliasSlice = createSlice({
  name: 'alias',
  initialState: {
    entities: [] as AliasType[],
    entityHistory: [] as AliasType[],
    initialLoad: false,
    loading: false,
    error: null,
  },
  reducers: {
    setAliases(state, action: PayloadAction<AliasType[]>) {
      state.entities = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/logout', state => {
        state.entities = []
        state.entityHistory = []
        state.initialLoad = false
        state.loading = false
        state.error = null
      })

    builder.addCase(createAliasAsync.fulfilled, (state, action) => {
      state.entities = state.entities.concat(action.payload)
    })

    builder.addCase(fetchAliasAsync.fulfilled, (state, action) => {
      state.entities = action.payload
      state.initialLoad = true
    })

    builder
      .addCase(updateAliasAsync.pending, (state, payload) => {
        state.entities = state.entities.map(a => {
          if (a.id === payload.meta.arg.id) {
            state.entityHistory = state.entityHistory.concat(a)
            return { ...a, ...payload.meta.arg }
          } else {
            return a
          }
        })
      })
      .addCase(updateAliasAsync.fulfilled, (state, payload) => {
        state.entityHistory = state.entityHistory.filter(a => a.id !== payload.meta.arg.id)
      })
      .addCase(updateAliasAsync.rejected, (state, payload) => {
        state.entities = state.entities.map(a => {
          if (a.id === payload.meta.arg.id) {
            return state.entityHistory?.find(e => e.id === payload.meta.arg.id) || a
          } else {
            return a
          }
        })

        state.entityHistory = state.entityHistory.filter(a => a.id !== payload.meta.arg.id)
      })

    builder
      .addCase(deleteAliasAsync.pending, (state, payload) => {
        state.entities = state.entities.filter(a => a.id !== payload.meta.arg)
        state.entityHistory = state.entityHistory.concat(state.entities.find(a => a.id === payload.meta.arg) || [])
      })
      .addCase(deleteAliasAsync.rejected, (state, payload) => {
        state.entities = state.entities.concat(state.entityHistory.find(a => a.id === payload.meta.arg) || [])
        state.entityHistory = state.entityHistory.filter(a => a.id !== payload.meta.arg)
      })
      .addCase(deleteAliasAsync.fulfilled, (state, payload) => {
        state.entityHistory = state.entityHistory.filter(a => a.id !== payload.meta.arg)
      })
  }
})

export const { setAliases } = aliasSlice.actions
export default aliasSlice.reducer