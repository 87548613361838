import { useLocation } from 'react-router-dom'
import './index.scss'
import Item from './Item'
import Selector from './Selector'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import Button from '../Button'
import { useAppThunkDispatch } from '../../store'
import { logoutAsync } from '../../store/auth'

const items = [
  { text: 'Dashboard', to: '/' },
  { text: 'Profile', to: '/profile' },
  // { text: 'Billing', to: '/billing' },
]

const Drawer = () => {
  const location = useLocation()
  const dispatchAsync = useAppThunkDispatch()

  return (
    <div id="drawer">
      <div className="drawer__content">
      <div id="logo">
        <Logo height={50} />
        <p>Beta</p>
      </div>

      <div id="list">
        <Selector position={items.findIndex(({ to }) => to === location.pathname)} />
        {
          items.map(item => <Item
            key={item.to}
            selected={item.to === location.pathname}
            text={item.text}
            to={item.to}
          />)
        }

        <div style={{ flex: 1 }} />

        <Button
          color="danger"
          onClick={() => dispatchAsync(logoutAsync())}
        >
          Logout
        </Button>
      </div>
      </div>
    </div>
  )
}

export default Drawer
