import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Tooltip, TooltipWrapper } from 'react-tooltip'
import Label from '../../components/Label'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, useAppThunkDispatch } from '../../store'
import { createTrueEmailAsync, deleteTrueEmailAsync } from '../../store/profile'
import classNames from 'classnames'
import { useState } from 'react'
import { validateEmail } from '../../utils'
import { addNotification } from '../../store/notifications'
import Button from '../../components/Button'
import { resendVerificationEmail } from '../../services/profile'
import ModalDelete from './ModalDelete'
import ModalAccountDeletion from './ModalAccountDeletion'
import { accountDeleteAsync } from '../../store/auth'
import { changePassword } from '../../services/auth'
import { notifyError } from '../../logger'

const Profile = () => {
  const dispatchAsync = useAppThunkDispatch()
  const dispatch = useDispatch()
  const trueEmails = useSelector((state: RootState) => state.profile.data.trueEmails)
  const [email, setEmail] = useState('')
  const [creatingTrueEmail, setCreatingTrueEmail] = useState(false)
  const [resendingVerificationEmail, setResendingVerificationEmail] = useState<string[]>([])
  const [deletingTrueEmail, setDeletingTrueEmail] = useState<string[]>([])
  const [trueEmailToDelete, setTrueEmailToDelete] = useState<string>('')
  const [showDelete, setShowDelete] = useState(false)
  const [showAccountDeletion, setShowAccountDeletion] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [changingPassword, setChangingPassword] = useState(false)

  const onChangePassword = () => {
    setChangingPassword(true)
    changePassword(currentPassword, newPassword)
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          message: 'Password changed successfully',
        }))
        setCurrentPassword('')
        setNewPassword('')
      })
      .catch(err => {
        if (err.message === 'WRONG_PASSWORD') {
          dispatch(addNotification({
            type: 'error',
            message: 'Your current password is wrong.',
          }))
        } else {
          notifyError(err)
          dispatch(addNotification({
            type: 'error',
            message: 'Something went wrong, we have been notified!',
          }))
        }
      })
      .finally(() => {
        setChangingPassword(false)
      })
  }

  const onAccountDelete = () => {
    dispatchAsync(accountDeleteAsync())
      .unwrap()
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          message: "We got your request, and sad to see you leaving!",
        }))
      })
  }

  const onDelete = (id: string) => {
    setShowDelete(false)
    setDeletingTrueEmail(ids => ids.concat(id))
    dispatchAsync(deleteTrueEmailAsync(id))
      .unwrap()
      .then(() => {
        dispatch(addNotification({
          type: 'success',
          message: 'Email deleted successfully',
        }))
      })
      .catch(() => {
        dispatch(addNotification({
          type: 'error',
          message: 'Something went wrong, we have been notified!',
        }))
      })
      .finally(() => {
        setDeletingTrueEmail(ids => ids.filter(xid => xid !== id))
      })
  }

  const trueEmailIsValid = () => {
    return email.trim().length > 0
      && validateEmail(email)
      && !trueEmails.some(e => e.email.toLowerCase().trim() === email.toLowerCase().trim())
  }

  return (
    <div id="profile">
      <ModalDelete
        id={trueEmailToDelete}
        opened={showDelete}
        onConfirm={onDelete}
        onClose={() => setShowDelete(false)}
      />

      <ModalAccountDeletion
        opened={showAccountDeletion}
        onConfirm={onAccountDelete}
        onClose={() => setShowAccountDeletion(false)}
      />

      <Tooltip />
      <h1>Profile</h1>

      <h2>
        Verified emails
        &nbsp;
        <TooltipWrapper content="Emails that can be used as destination">
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="xs"
          />
        </TooltipWrapper>
      </h2>

      <div className="row profile-actions">
        <input
          className={classNames('input', {
            'input--error': !trueEmailIsValid() && email.length > 0,
          })}
          disabled={creatingTrueEmail}
          type="email"
          placeholder="Type in a real email address"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        <Button
          disabled={!trueEmailIsValid()}
          loadable={true}
          loading={creatingTrueEmail}
          onClick={() => {
            setCreatingTrueEmail(true)
            dispatchAsync(createTrueEmailAsync(email))
              .unwrap()
              .then(() => {
                dispatch(addNotification({
                  type: 'success',
                  message: 'Email added successfully',
                }))
                setEmail('')
              })
              .catch(() => {
                dispatch(addNotification({
                  type: 'error',
                  message: 'Something went wrong, we have been notified!',
                }))
              })
              .finally(() => {
                setCreatingTrueEmail(false)
              })
          }}
        >
          <FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Add email
        </Button>
      </div>

      {
        trueEmails.map(email => (
          <div className="email-item" key={email.email}>
            <div className="email-item__content">
              <div className="email-item__alias">
                {email.email}
              </div>

              <Label
                text={email.verified ? 'Verified' : 'Not verified'}
                status={email.verified ? 'success' : 'warning'}
              />
            </div>

            <div className="email-item__actions">
              {
                !email.verified &&
                <>
                  <Button
                    color="light"
                    loadable={true}
                    loading={resendingVerificationEmail.includes(email.id)}
                    onClick={() => {
                      setResendingVerificationEmail(resendingVerificationEmail => [...resendingVerificationEmail, email.id])
                      resendVerificationEmail(email.id)
                        .then(() => {
                          dispatch(addNotification({
                            type: 'success',
                            message: 'Verification email sent successfully',
                          }))
                        })
                        .catch(err => {
                          notifyError(err)
                          dispatch(addNotification({
                            type: 'error',
                            message: 'Something went wrong, we have been notified!',
                          }))
                        })
                        .finally(() => {
                          setResendingVerificationEmail(resendingVerificationEmail => resendingVerificationEmail.filter(id => id !== email.id))
                        })
                    }}
                  >
                    Resend verification email
                  </Button>

                  <div style={{ marginRight: '5px' }} />
                </>
              }

              <Button
                color="danger"
                loadable={true}
                loading={deletingTrueEmail.includes(email.id)}
                onClick={() => {
                  if (email.verified) {
                    setTrueEmailToDelete(email.id)
                    setShowDelete(true)
                  } else {
                    onDelete(email.id)
                  }
                }}
              >
                <FontAwesomeIcon
                  color="white"
                  icon={faTrashAlt}
                />
              </Button>
            </div>
          </div>
        ))
      }

      {/* <h2>Change my email</h2> */}

      <h2>Change my password</h2>
      <label>Current password</label>
      <input
        className="input"
        placeholder="your password"
        type="password"
        value={currentPassword}
        onChange={e => setCurrentPassword(e.target.value)}
      />
      <br />
      <label>New password</label>
      <input
        className="input"
        placeholder="your new password"
        type="password"
        value={newPassword}
        onChange={e => setNewPassword(e.target.value)}
      />
      <br />

      <Button
        color="light"
        loadable
        loading={changingPassword}
        onClick={onChangePassword}
      >
        <FontAwesomeIcon icon={faTrashAlt} />&nbsp;&nbsp;
        Change password
      </Button>

      <br />
      <br />
      <Button
        color="danger"
        onClick={() => setShowAccountDeletion(true)}
      >
        <FontAwesomeIcon icon={faTrashAlt} />&nbsp;&nbsp;
        Delete my account
      </Button>
    </div>
  )
}

export default Profile