import React from 'react'
import MainLayout from '../layouts/main'
import AuthLayout from '../layouts/Auth'
import { Route, Routes, useLocation } from 'react-router-dom'
import ProtectedRoute from '../components/ProtectedRoute'
import Dashboard from './Dashboard'
import Billing from './Billing'
import Profile from './Profile'
import VerifyEmail from './VerifyEmail'
import Login from './Login'
import Register from './Register'
import { useEffect } from 'react'
import { analytics } from '../plugins/firebase'
import { logEvent } from 'firebase/analytics'

const Router = () => {
  const location = useLocation()

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_path: location.pathname })
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>}>
        <Route index element={<Dashboard />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/profile" element={<Profile />} />
      </Route>

      <Route path="/verify" element={<ProtectedRoute><VerifyEmail /></ProtectedRoute>} />

      <Route element={<AuthLayout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Route>
    </Routes>
  )
}

export default Router