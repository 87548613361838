import { Outlet } from 'react-router-dom'
import Drawer from '../components/Drawer'
import './index.scss'

const MainLayout = () => {
  return (
    <div id="page">
      <Drawer />

      <div id="content">
        <Outlet />
      </div>
    </div>
  )
}

export default MainLayout