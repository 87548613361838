import { register } from '../../services/auth'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { validateEmail } from '../../utils'
import Button from '../../components/Button'
import { addNotification } from '../../store/notifications'
import { useDispatch } from 'react-redux'
import { notifyError } from '../../logger'

type Errors = {
  [key: string]: {
    message: string
  }
}

const Register = () => {
  const [ email, setEmail ] = useState<string>('')
  const [ password, setPassword ] = useState<string>('')
  const [errors, setErrors] = useState<Errors>({})
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)
  const [registering, setRegistering] = useState<boolean>(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation()
    event.preventDefault()

    setRegistering(true)
    register(email, password)
      .then(() => {
        setEmail('')
        setPassword('')
        setAcceptTerms(false)
        dispatch(addNotification({
          message: 'We have sent you a verification email. Please verify your email address before logging in!',
          type: 'success',
        }))
        navigate('/login')
      })
      .catch(error => {
        if (error.message === 'EMAIL_EXISTS') {
          setErrors(errors => ({ ...errors, server: { message: 'Account already exists. Log in instead!' } }))
        } else {
          notifyError(error)
          setErrors(errors => ({ ...errors, server: { message: 'Apologies. We cannot register your account at this moment, and we have been notified.' } }))
        }
      })
      .finally(() => {
        setRegistering(false)
      })
  }

  useEffect(() => {
    setErrors(errors => {
      const { email } = errors
      return { email }
    })
  }, [email, password])

  return (
    <>
      <p className="freemessage">Start free today.</p>
      <h1>Create a new account<span style={{ marginLeft: '5px', color: '#FFF509', fontSize: '2em' }}>.</span></h1>

      <p>
        Got an account? <Link to="/login">Log in.</Link>
      </p>

      <form onSubmit={onSubmit}>
        <input
          className={classnames('input', { 'input--error': !!errors.email })}
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={event => {
            const email = event.target.value.trim()
            setEmail(email)
            if (!validateEmail(email) && email.length > 0) {
              setErrors(errors => ({ ...errors, email: { message: 'incorrect email' }}))
            } else {
              setErrors(errors => {
                const { email, ...rest } = errors
                return rest
              })
            }
          } }
        />

        <input
          className="input"
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={event => setPassword(event.target.value.trim())}
        />

        <label>
          <input
            checked={acceptTerms}
            type="checkbox"
            onChange={() => setAcceptTerms(!acceptTerms)}
          />
          I agree to the&nbsp;<Link to="/terms">Terms of Service</Link>&nbsp;and&nbsp;<Link to="/privacy">Privacy Policy</Link>.
        </label>

        <Button
          disabled={!validateEmail(email) || password.length === 0 || email.length === 0 || registering || !acceptTerms}
          loading={registering}
          loadable
          type="submit"
        >
          Create your account
        </Button>
      </form>

      {!!errors.server &&
        <div style={{ position: 'relative' }}>
          <div className="error-container">
            <div className="error-container__error">
              {errors.server.message}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Register