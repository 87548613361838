import Modal from '../../components/Modal'

type Props = {
  opened: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalCreate: React.FC<Props> = ({
  opened,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal
      header="Are you sure?"
      destructive
      okActionText="Delete"
      cancelActionText="Cancel"
      Content={
        <p className="delete-alias">
          Your account will be deleting your account.
          <br />
          This action is irreversible.
          <br />
          <br />
          <strong>Are you sure you want to delete?</strong>
        </p>
      }
      opened={opened}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )
}

export default ModalCreate