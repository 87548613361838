import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import 'react-tooltip/dist/react-tooltip.css'
import { TooltipProvider } from 'react-tooltip'
import Notifications from './components/Notifications'
import './plugins/axios'
import Initializer from './components/Initializer'
import Router from './routes'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './plugins/bugsnag'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <TooltipProvider>
        <Provider store={store}>
          <Notifications />
          <Initializer>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </Initializer>
        </Provider>
      </TooltipProvider>
    </React.StrictMode>
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
