import { RootState, useAppThunkDispatch } from '../../store'
import { addNotification } from '../../store/notifications'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import { createAliasAsync } from '../../store/alias'

type Props = {
  opened: boolean
  onClose: () => void
}

const defaultDomain = process.env.REACT_APP_ENVIRONMENT === 'prod' ?  'zerocrumb.com' : 'nonprod.zerocrumb.com'

const ModalCreate: React.FC<Props> = ({
  opened,
  onClose,
}) => {
  const [prefix, setPrefix] = useState('')
  const trueEmails = useSelector((state: RootState) => state.profile.data.trueEmails)

  const dispatch = useDispatch()
  const dispatchAsync = useAppThunkDispatch()

  return (
    <Modal
      disableAction={prefix.length === 0}
      header="Create new alias"
      Content={
        <div className="create-alias">
          <input
            placeholder="enter a prefix..."
            type="text"
            value={prefix}
            onChange={(event) => setPrefix(event.target.value.trim())}
          />
          <div className="create-alias__suffix">
            .uwer723fwef@{defaultDomain}
          </div>
        </div>
      }
      opened={opened}
      onConfirm={() => {
        dispatchAsync(createAliasAsync({
          prefix: prefix,
          forwardTo: [ trueEmails.find(e => e.verified && e.primary)?.email || '' ].filter(email => email.length > 0),
          domain: defaultDomain,
        }))
          .unwrap()
          .then(alias => {
            dispatch(addNotification({
              message: `Alias ${alias.alias} created`,
              type: 'success',
            }))
          })
          .catch(() => {
            dispatch(addNotification({
              message: 'Something went wrong!',
              type: 'error',
            }))
          })

        setPrefix('')
        onClose()
      }}
      onClose={onClose}
    />
  )
}

export default ModalCreate