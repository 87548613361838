import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { animated, useSpring } from 'react-spring'
import './styles.scss'

type Props = {
  children: React.ReactNode
  color?: 'primary' | 'secondary' | 'danger' | 'light' | 'dark'
  clear?: boolean
  className?: string
  disabled?: boolean
  loadable?: boolean
  loading?: boolean
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

const Button: React.FC<Props> = ({
  children,
  color = 'primary',
  clear = false,
  disabled = false,
  loadable = false,
  loading = false,
  onClick,
}) => {
  const { anim } = useSpring({
    anim: loading ? 1 : 0,
  })

  return (
    <button
      className={classnames('button', clear ? 'button--clear' : `button--${color}` )}
      disabled={disabled || loading}
      onClick={() => !!onClick && onClick()}
    >
      {
        loadable ? (
          <animated.div
            className="button__loadable-content"
            style={{
              transform: anim
                .to({
                  range: [0, 1],
                  output: ['translateY(-100%)', 'translateY(0%)'],
                })
            }}
          >
            <animated.div style={{
              opacity: anim
            }}>
              <FontAwesomeIcon
                className="fa-spin"
                color="#black"
                size="lg"
                icon={faSpinner}
              />
            </animated.div>

            <animated.div style={{
              opacity: anim
                .to({
                  range: [0, 1],
                  output: [1, 0],
                })
            }}>
              {children}
            </animated.div>
          </animated.div>
        ) : children
      }
    </button>
  )
}

export default Button