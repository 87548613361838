import app from '../plugins/firebase'
import { getFirestore, collection, onSnapshot, where, query } from 'firebase/firestore'

export type MetricsType = {
  alias: string
  blockCount: number
  forwardCount: number
  lastForwardedAt: Date | null
  lastBlockedAt: Date | null
}
const db = getFirestore(app)
const coll = collection(db, 'metrics')

/**
 * @desc subscribe to list of bookmarks
 */

let subscription: (() => void) | undefined = undefined

export const subscribeToAliasMetrics = (uid: string, cb: (metrics: MetricsType[]) => void) => {
  /** unsubscribe before re-subscribing */
  subscription && subscription()

  subscription = onSnapshot(query(coll, where('uid', '==', uid)), firebaseResponse => {
    if (!firebaseResponse || firebaseResponse?.empty) {
      return cb([])
    } else {
      return cb(
        firebaseResponse
          .docs
          .map(doc => {
            const { uid, ...data } = doc.data()
            return data as MetricsType
          })
      )
    }
  })
}

/**
 * unsubscribe from bookmarks
 */
export const unsubscribeFromBookmarksUpdates = () => {
  if (subscription) subscription()
}