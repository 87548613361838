import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent as firebaseLogEvent } from 'firebase/analytics'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import firebaseConfig from './firebase-config.json'

const app = initializeApp(firebaseConfig)

if (process.env.NODE_ENV === 'development') {
  const auth = getAuth()
  connectAuthEmulator(auth, 'http://localhost:9099')
}

export const analytics = getAnalytics(app)
export const logEvent = firebaseLogEvent.bind(null, analytics)
export default app