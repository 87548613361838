import Bugsnag from '@bugsnag/js'

type MessageType = {
  message: string
  name: string
} | Error

export const notifyError = (payload: MessageType) => {
  if (process.env.REACT_APP_DEPLOYED === 'true') {
    Bugsnag.notify(payload)
  } else {
    console.error(payload)
  }
}