import './styles.scss'
import EmailItem from './EmailItem'
import { useState } from 'react'
import { RootState, useAppThunkDispatch } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { AliasType } from '../../services/alias'
import { updateAliasAsync } from '../../store/alias'
import { Tooltip } from 'react-tooltip'
import EmptyList from './EmptyList'
import { addNotification } from '../../store/notifications'
import Button from '../../components/Button'
import ModalCreate from './ModalCreate'
import ModalDelete from './ModalDelete'

const Dashboard = () => {
  const [showCreate, setShowCreate] = useState(false)
  const aliases = useSelector((state: RootState) => state.alias.entities)
  const [aliasToDelete, setAliasToDelete] = useState<AliasType>()
  const [showDelete, setShowDelete] = useState(false)
  const aliasMaxedOut = useSelector((state: RootState) => state.profile.data.billing.plan === 'free' && state.alias.entities.length >= 5)
  const metrics = useSelector((state: RootState) => state.metrics.entities
    .reduce((acc, curr) => ({
      blockCount: acc.blockCount + curr.blockCount,
      forwardCount: acc.forwardCount + curr.forwardCount,
    }), { blockCount: 0, forwardCount: 0 })
  )
  const dispatch = useDispatch()
  const dispatchAsync = useAppThunkDispatch()

  return (
    <div
      className="alias-view"
      style={{ flex: 1 }}
    >
      <Tooltip />

      <ModalCreate
        opened={showCreate}
        onClose={() => setShowCreate(false)}
      />

      <ModalDelete
        alias={aliasToDelete}
        opened={showDelete}
        onClose={() => setShowDelete(false)}
      />

      <h1>Dashboard</h1>

      {
        aliases.length === 0 ? (
          <EmptyList onCreate={() => setShowCreate(true)}  />
        ) : (
          <>
            <div className="stats global">
              <p>Emails forwarded: <span className="metric">{metrics.forwardCount}</span></p>
              <p>Spam blocked: <span className="metric">{metrics.blockCount}</span></p>
              <p>Aliases created: <span className="metric">{aliases.length}</span></p>
            </div>

            <div className="actions">
              <Button
                color="light"
                disabled={aliasMaxedOut}
                onClick={() => setShowCreate(true)}
              >
                + Create new alias
              </Button>
            </div>

            {aliasMaxedOut && (
              <div className="alias-view__maxed-out">
                You have reached the maximum number of aliases on the <b>beta</b>.
                <br />
                Please send us an email at <a href="mailto:sales@zerocrumb.com">sales@zerocrumb.com</a> to request it.
              </div>
            )}

            {aliases.map((alias) => (
              <EmailItem
                key={alias.id}
                alias={alias.alias}
                forwardTo={alias.forwardTo}
                paused={alias.paused}
                onDelete={() => {
                  setAliasToDelete(() => alias)
                  setShowDelete(() => true)
                }}
                onUpdate={async (update: Partial<AliasType>) => {
                  dispatchAsync(updateAliasAsync({ id: alias.id, ...update }))
                    .unwrap()
                    .then(() => {
                      dispatch(addNotification({
                        message: `Alias ${alias.alias} updated`,
                        type: 'success',
                      }))
                    })
                    .catch(() => {
                      dispatch(addNotification({
                        message: 'Something went wrong!',
                        type: 'error',
                      }))
                    })
                }}
              />
            ))}
          </>
        )
      }
    </div>
  )
}

export default Dashboard
