import { animated, useSpring } from "react-spring"

type Props = {
  position: number
}

const Selector: React.FC<Props> = ({
  position,
}) => {
  const rStyle = useSpring({ transform: `translateY(${position * 80}px)` })

  return (
    <animated.div
      style={rStyle}
    >
      <svg
        className="selector"
        viewBox="0 0 150 80"
        height="80px"
        width="150px"
      >
        <path
          d="M0,40 q0 -20,20 -20 h110 q20 0,20 -20 v80 q0 -20,-20 -20 h-110 q-20 0,-20 -20z"
          fill="white"
        />
      </svg>
    </animated.div>
  )
}

export default Selector