import { createUserWithEmailAndPassword, EmailAuthProvider, getAuth, onAuthStateChanged, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import app from '../plugins/firebase'

const auth = getAuth(app)

/**
 * @param email
 * @param password
 */
export const register = (email: string, password: string): Promise<void> =>
  createUserWithEmailAndPassword(auth, email, password)
    .then(() => Promise.resolve())
    .catch((error) => {
      const errorCode = error.code

      switch (errorCode) {
        case 'auth/email-already-in-use':
          return Promise.reject(new Error('EMAIL_EXISTS'))
        default:
          return Promise.reject(new Error('UNKNOWN_ERROR'))
      }
    })

/**
 * @desc signs out
 */
export const signOut = (): Promise<void> =>
  auth.signOut()

/**
 * @param email
 * @param password
 */
export const login = (email: string, password: string): Promise<{ uid: string, verified: boolean }> => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(({ user }) => {
      return {
        uid: user.uid,
        verified: user.emailVerified,
      }
    })
    .catch((error) => {
      const errorCode = error.code

      switch (errorCode) {
        case 'auth/invalid-credential':
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          return Promise.reject(new Error('INVALID_CREDENTIALS'))
        case 'auth/user-disabled':
          return Promise.reject(new Error('USER_DISABLED'))
        default:
          return Promise.reject(new Error('UNKNOWN_ERROR'))
      }
    })
}

export const verifyAuth = (): Promise<{ uid: string, verified: boolean }> =>
  new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        resolve({
          uid: user.uid,
          verified: user.emailVerified,
        })
      } else {
        // No user is signed in.
        return reject(new Error('NO_LOGGED_IN'))
      }
    })
  })

export const changePassword = async (password: string, newPassword: string): Promise<void> => {
  const user = getAuth().currentUser
  if (user) {
    try {
      await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email || '', password))
      await updatePassword(user, newPassword)
    } catch (err: any) {
      const errorCode = err.code

      switch (errorCode) {
        case 'auth/wrong-password':
          return Promise.reject(new Error('WRONG_PASSWORD'))
        case 'auth/weak-password':
          return Promise.reject(new Error('WEAK_PASSWORD'))
        default:
          return Promise.reject(new Error('UNKNOWN_ERROR'))
      }
    }
  }
}

/**
 * @param email
 */
export const verifyEmail = (email: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), 500)
  })
}

export const accountDelete = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), 1000)
  })
}