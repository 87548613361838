import axios from 'axios'

export type TrueEmailType = {
  id: string
  email: string
  verified: boolean
  primary: boolean
}

export type Profile = {
  trueEmails: TrueEmailType[]
  billing: {
    plan: 'free' | 'beginner' | 'pro' | 'enterprise'
    status: 'active' | 'past_due' | 'canceled' | 'unpaid'
  }
}

export const createTrueEmail = (email: string): Promise<TrueEmailType> =>
  axios.post('/profile/true-emails', { email })
    .then(res => res.data as TrueEmailType)

export const deleteTrueEmail = (id: string): Promise<void> =>
  axios.delete(`/profile/true-emails/${id}`)
    .then(res => res.data as void)

export const resendVerificationEmail = (id: string): Promise<void> =>
  axios.post(`/profile/true-emails/${id}/resend-verify`)
    .then(res => res.data as void)

export const verifyEmail = (id: string, token: string): Promise<void> =>
  axios.post(`/profile/true-emails/${id}/verify`, { token })
    .then(res => res.data as void)

export const fetchProfile: () => Promise<Profile> = () =>
  axios.get('/profile')
    .then(res => res.data as Profile)
