import Button from '../../components/Button'

type Props = {
  onCreate: () => void
}

const EmptyList: React.FC<Props> = ({ onCreate }) => {
  return (
    <div className="empty-list">
      <div className="empty-list__title">You don't have any aliases yet</div>
      <div className="empty-list__subtitle">
        Aliases are email addresses that forward to your primary email address.
        <br />
        You can create up to 5 aliases in our <strong>beta</strong> version.
        <br />
        If you need more, please contact us at <a href="mailto:sales@liveeasy.io">sales@liveeasy.io</a>
      </div>

      <Button
        color="light"
        onClick={onCreate}
      >
        Create new alias
      </Button>
    </div>
  )
}

export default EmptyList